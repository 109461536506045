import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#331714",
        primaryLighten80: "#5C4543",
        primaryLightGrey75: "#F6F4F2",
        primary2: "#FF5234",
        mainGrey100: "#666666",
        secondary: "#003B42",
        secondary50: "#998B8A",
        secondary2: "#99B1B3",
        secondary3: "#FFBAAE",
        neutralLighten100: "#F1EEEC",
        lightGrey100: "#F1EEEC",
        neutralDark50: "#E0DCD8",
        neutralDark75: "#CFC9C4",
        darkGrey25: "#E9E6E3",
        darkGrey75: "#CFC9C4",
        darkGrey100: "#BDB6B0",
        accent: "#FF4E2A",
        accentDarker110: "#CC3E22",
        success100: "#17B26A",
        warning100: "#FDB022",
        error: "#FF5252",
        error100: "#D32F2F",
        info: "#2196F3",
        info100: "#2970FF",
        success: "#4CAF50",
        warning: "#FFC107",
        warning50: "FFFAEB",
        warning110: "DC6803",
      },
    },
  },
});
