import Vue from "vue";
import Vuex from "vuex";
import Vessels from "./clients/Vessels.module";
import Incidents from "./clients/Incidents.module";
import Auth from "./clients/Auth.module";
import Announcement from "./clients/Announcement.module";
import Benefits from "./clients/Benefits.module";
import LongTrends from "./clients/LongTrends.module";
import User from "./clients/User.module";
import Users from "./clients/Users.module";
import VesselsAdmin from "./clients/VesselsAdmin.module";
import VesselAccessGroups from "./clients/VesselAccessGroups.module";
import VesselEvents from "./clients/VesselEvents.module";
import VesselGroups from "./clients/VesselGroups.module";
import VesselsHistories from "./clients/VesselsHistories.module";
import Companies from "./clients/Companies.module";
import Dashboard from "./clients/Dashboard.module";
import MapWidget from "./clients/MapWidget.module";
import Taxonomy from "./clients/Taxonomy.module";
import Reports from "./clients/Reports.module";
import LogVariables from "./clients/LogVariables.module";
import LogData from "./clients/LogData.module";
import LongTrendPeriod from "./clients/LongTrendPeriod.module";
import VesselDataWidgetConfiguration from "./clients/VesselDataWidgetConfig.module";
import WidgetTypes from "./clients/WidgetTypes.module";
import PerformanceStatusWidget from "./clients/PerformanceStatusWidget.module";
import NotificationWidgetConfiguration from "./clients/NotificationWidget.module";
import CiiWidgetConfiguration from "./clients/CiiWidget.module";
import FoulingOverviewWidgetConfiguration from "./clients/FoulingOverviewWidget.module";
import Snackbar from "./clients/Snackbar.module";
import Cii from "./clients/Cii.module";
import Shapoli from "./clients/Shapoli.module";
import ISO19030 from "./clients/ISO19030.module";
import Channels from "./clients/Channels.module";
import Fouling from "./clients/Fouling.module";
import DataQuality from "./clients/DataQuality.module";
import FeatureMap from "./clients/FeatureMap.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    Announcement,
    Benefits,
    LongTrends,
    Incidents,
    User,
    Users,
    Vessels,
    VesselsAdmin,
    Companies,
    VesselAccessGroups,
    VesselGroups,
    VesselsHistories,
    Dashboard,
    MapWidget,
    Taxonomy,
    Reports,
    VesselEvents,
    LogVariables,
    LogData,
    LongTrendPeriod,
    VesselDataWidgetConfiguration,
    WidgetTypes,
    PerformanceStatusWidget,
    NotificationWidgetConfiguration,
    CiiWidgetConfiguration,
    FoulingOverviewWidgetConfiguration,
    Snackbar,
    Cii,
    Shapoli,
    ISO19030,
    Channels,
    Fouling,
    DataQuality,
    FeatureMap,
  },
});
