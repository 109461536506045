




























































import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import store from "@/store";
import dateHelper from "Utilities/date-helper";
//  types
import { ManageOutliersRow } from "@/types/dataQuality/ManageOutliersRow";
import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";
import { DataOutlier } from "@/types/dataQuality/dataOutliers";
//  components
import ManageOutliersTable from "@/components/dataQuality/ManageOutliersTable.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import DataQualityModule from "@/store/clients/DataQuality.module";
import VesselsModule from "@/store/clients/Vessels.module";

const DataQuality = getModule(DataQualityModule, store);
const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    ManageOutliersTable,
  },
})
export default class ManageModal extends Vue {
  @PropSync("value", { required: true }) isModalActive!: boolean;
  @Prop({ type: String, default: "1048px" }) width!: string;
  @Prop() data!: { featureName: string; vesselId: number; vesselName: string; outliers: DataOutlier[] }[];

  selectedRows: ManageOutliersRow[] = [];
  selectedColumn = "";
  searchTerm = "";

  //  @Getters
  get loading(): boolean {
    return DataQuality.modalLoadingState;
  }

  get headers(): DataQualityHeader[] {
    return [
      { text: "Vessel", value: "vesselName" },
      { text: "Data Tag", value: "featureName" },
      { text: "Outlier Value", value: "value" },
      { text: "Date & Time", value: "date" },
      { text: "Impacts", value: "impact" },
    ];
  }

  get rows(): ManageOutliersRow[] {
    return this.data.flatMap(item =>
      item.outliers
        .map(outlier => ({
          id: outlier.id,
          vesselName: item.vesselName,
          featureName: item.featureName,
          value: Number(outlier.value).toFixed(6),
          timestamp: outlier.timestamp,
          date: `${this.getFormatedDateTimeString(outlier.timestamp)} UTC`,
          isDeleted: outlier.isDeleted,
          isAcknowledged: outlier.isAcknowledged,
          impact: "",
        }))
        .filter(outlier => outlier.isAcknowledged !== true && outlier.isDeleted !== true)
    );
  }

  get vesselIds(): number[] {
    const vesselIds = this.data.filter(item => item.outliers.length > 0).map(item => item.vesselId);
    return Array.from(new Set(vesselIds));
  }

  get tagsList(): string[] {
    const featureNames = this.rows.map(row => row.featureName);
    return Array.from(new Set(featureNames));
  }

  get isDisabled(): boolean {
    return this.selectedRows.length === 0;
  }

  // @Methods
  open(): void {
    this.isModalActive = true;
    this.$emit("open");
  }

  close(): void {
    this.isModalActive = false;
    this.$emit("close");
  }

  async updateOutliersAcknowledge(): Promise<void> {
    const acknowledgedOutliers = this.selectedRows.map(row => {
      return {
        id: row.id,
        isAcknowledged: true,
        isDeleted: row.isDeleted,
        value: Number(row.value),
        timestamp: row.timestamp,
      };
    });
    await DataQuality.updateOutliersAcknowledge(acknowledgedOutliers);
    this.$emit("updateOutliers");
    this.selectedRows = [];
    this.close();
  }

  async updateOutliersRemove(): Promise<void> {
    const acknowledgedOutliers = this.selectedRows.map(row => {
      return {
        id: row.id,
        isAcknowledged: row.isAcknowledged,
        isDeleted: true,
        value: Number(row.value),
        timestamp: row.timestamp,
      };
    });
    await DataQuality.updateOutliersRemove(acknowledgedOutliers);
    this.$emit("updateOutliers");
    this.selectedRows = [];
    this.close();
  }

  getFormatedDateTimeString(timestamp: string): string {
    return dateHelper.getFormatedDateTimeString(timestamp);
  }

  //  @Hooks
  async created(): Promise<void> {
    await Promise.all([Vessels.refreshExtendedVessels()]);
  }
}
