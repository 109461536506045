import { CurveFunction } from "@/types/PowerSpeedCurveConfig";
export interface SfocPowerConfig {
  id: number;
  description: string;
  minSfoc: number;
  maxSfoc: number;
  minPower: number;
  maxPower: number;
  vesselIds: number[];
  curves: SfocPowerCurve[];
}

export interface SfocPowerCurve {
  id: number;
  configId: number;
  tier: EngineTier;
  tierValue: number;
  engines: SfocPowerCurveEngine[];
  segments: SfocPowerCurveData[];
}

export interface SfocPowerCurveEngine {
  id: number;
  engineType: EngineType;
  curveId: number;
}

export interface SfocPowerCurveData {
  id: number;
  curveId: number;
  curveFunction: CurveFunction;
  fromPower: number;
  toPower: number;
  parameters: SfocPowerCurveParameter[];
}

export interface SfocPowerCurveParameter {
  id: number;
  curveDataId: number;
  constant: string;
  value: number;
}

export enum EngineTier {
  Tier2 = "Tier2",
  Tier3 = "Tier3",
}

export enum EngineType {
  Undefined = "Undefined",
  Me1 = "Me1",
  Me2 = "Me2",
  Ge1 = "Ge1",
  Ge2 = "Ge2",
  Ge3 = "Ge3",
  Ge4 = "Ge4",
  Ge5 = "Ge5",
  Ge6 = "Ge6",
}

export enum Constant {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
}
