export const headers = [
  {
    text: "Feature",
    type: "string",
    align: "left",
    value: "feature",
    tooltip: "Global metrics derived from Data Tags or other Features",
    sortable: true,
  },
  {
    text: "Data Tag",
    type: "datatag",
    align: "left",
    value: "datatag",
    tooltip: "Standardized labels that unify Variable names and units",
    width: "280px",
    sortable: true,
  },
  {
    text: "Variable",
    type: "variable",
    align: "left",
    value: "variable",
    tooltip: "Raw values received from the vessels or other sources",
    width: "280px",
    sortable: true,
  },
];
