























import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import moment from "moment";
//  types
import { ExtendedVessel } from "@/types/Vessel";
import { FeatureMapItem, RelatedDataTag } from "@/types/FeatureMap";
import { headers } from "@/components/FeatureMap/FeatureMapTable/headers";
//  components
import FeatureMapTable from "@/components/FeatureMap/FeatureMapTable/index.vue";

//  modules
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/clients/Snackbar.module";
import VesselsModule from "@/store/clients/Vessels.module";
import FeatureMapModule from "@/store/clients/FeatureMap.module";

const Snackbar = getModule(SnackbarModule, store);
const Vessels = getModule(VesselsModule, store);
const FeatureMap = getModule(FeatureMapModule, store);

@Component({
  components: {
    FeatureMapTable,
  },
})
export default class FeatureMapping extends Vue {
  searchQuery = "";
  headers = headers;

  //  @Getters
  get vessel(): ExtendedVessel | null {
    if (!Vessels.currentVessel) return null;
    return Vessels.currentVessel;
  }

  get featureEnabled(): boolean {
    if (!this.vessel) return false;

    return this.vessel.features.some(feature => feature.name === "Fouling");
  }

  get featureMapListLoadingState() {
    return FeatureMap.featureMapListLoadingState;
  }

  get featureMapList(): FeatureMapItem[] {
    return FeatureMap.featureMapList;
  }

  get rows(): any {
    const rows = this.featureMapList.map((item, index) => ({
      key: index,
      feature: {
        value: item.featureName,
        description: item.featureDescription,
        relatedFeatures: item.relatedFeatures,
        unit: item.relatedDataTags.length >= 1 ? item.relatedDataTags[0].unit : "N/A",
      },
      datatag: {
        type: this.dataCellType(item),
        value: item.relatedDataTags.map(tag => tag.name).join(", "),
        list: item.relatedDataTags,
        unit: item.relatedDataTags.length >= 1 ? item.relatedDataTags[0].unit : "N/A",
      },
      variable: {
        type: this.dataCellType(item),
        value: item.relatedDataTags
          .filter(tag => tag.logVariable)
          .map(tag => tag.logVariable.name)
          .join(", "),
        list: item.relatedDataTags.filter(tag => tag.logVariable).map(tag => tag.logVariable),
        unit:
          item.relatedDataTags.filter(tag => tag.logVariable).map(tag => tag.logVariable).length >= 1
            ? item.relatedDataTags.filter(tag => tag.logVariable).map(tag => tag.logVariable)[0]?.unit
            : "N/A",
      },
    }));

    if (this.searchQuery && this.searchQuery.trim()) {
      const query = this.searchQuery.toLowerCase();
      return rows.filter(item => item.feature.value.toLowerCase().includes(query) || item.datatag.value.toLowerCase().includes(query) || item.variable.value.toLowerCase().includes(query));
    }

    return rows;
  }

  //  @Methods
  async fetchData(): Promise<void> {
    if (!this.vessel) return;

    try {
      await FeatureMap.fetchFeatureMapList(this.vessel?.id);
    } catch (e) {
      Snackbar.showSnackbar({ text: "Failed to load feature mapping data" });
    }
  }

  dataCellType(data: any): string {
    if (data.relatedFeatures.length > 0) return "aggregated";
    return data.relatedDataTags.length === 1 ? "single" : "multiple";
  }

  //  @Hooks
  async created(): Promise<void> {
    await this.fetchData();
  }
}
