import net from "@/Scripts/app/net";
/* eslint-disable */
class DataQualityClient {
  constructor() {}
  getFleetOverview() {
    return [{
        vesselId: 556,
        overallStatus: 3,
        dataAvailability: 3,
        dataOutliers: 3,
      },
      {
        vesselId: 601,
        overallStatus: 1,
        dataAvailability: 1,
        dataOutliers: 1,
      },
      {
        vesselId: 602,
        overallStatus: 1,
        dataAvailability: 1,
        dataOutliers: 1,
      },
      {
        vesselId: 603,
        overallStatus: 0,
        dataAvailability: 0,
        dataOutliers: 0,
      },
      {
        vesselId: 606,
        overallStatus: 2,
        dataAvailability: 2,
        dataOutliers: 2,
      },
      {
        vesselId: 607,
        overallStatus: 1,
        dataAvailability: 1,
        dataOutliers: 1,
      }
    ];
    return net.get("/DataQuality/FleetOverview");
  }
  getDataOutage() {
    return net.get("/DataQuality/DataOutage");
  }
  getDataOutageConfig() {
    return net.get("/DataQuality/DataOutage/Configuration");
  }
  updateDataOutageConfig(config) {
    return net.put("/DataQuality/DataOutage/Configuration", config);
  }
  getCompanyOutliers(query) {
    // @params query = { companyId, fromDate?, toDate? };
    return net.get(`/DataQuality/CompanyOutliers`, query);
  }
  getFeatureConfigs(query) {
    // @params query = { vesselId };
    return net.get(`/DataQuality/Outliers/Config`, query);
  }
  updateFeatureConfigs(config) {
    return net.post(`/DataQuality/Outliers/Config`, config);
  }
  getFeatureValues() {
    return net.get(`/datafeature`);
  }
  updateOutliersAcknowledge(tags) {
    return net.put("/DataQuality/Outliers/TakeAction/Acknowledge", tags);
  }
  updateOutliersRemove(tags) {
    return net.put("/DataQuality/Outliers/TakeAction/Delete", tags);
  }

  getSpeedLog(query) {
    query.featureName = "Speed Through Water";
    return net.get("/DataQuality/SpeedLog", query);
  }
  getDerivedSpeedThroughWater(query) {
    return net.get("/DataQuality/SpeedLog/DerivedSpeedThroughWater", query);
  }

  getSpeedThroughWater(query) {
    return net.get("/DataQuality/SpeedLog/SpeedThroughWater", query);
  }
}

export default new DataQualityClient();
