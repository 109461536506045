





















































































































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import numberHelper from "Utilities/number-helper";
//  types
import { CurveFunction } from "@/types/PowerSpeedCurveConfig";
import { curveTypeFormula } from "@/consts/curveConfigDefaults";
import { SfocPowerCurveParameter, SfocPowerCurveData } from "@/types/SFOCPowerCurveConfig";

@Component({})
export default class SfocPowerCurveDataEditDialong extends Vue {
  @PropSync("value", { required: true }) isOpen!: boolean;
  @PropSync("maxWidth", { default: 1000 }) dialogWidth!: number;
  @Prop({ required: true }) curveData!: SfocPowerCurveData;

  clonedCurveData: SfocPowerCurveData | null = null;
  parameters: string[] = ["A", "B", "C", "D", "E", "F", "G"];

  get curveOptions(): string[] {
    return Object.keys(CurveFunction);
  }

  get showActions(): boolean {
    if (!this.clonedCurveData) return false;
    return this.clonedCurveData.curveFunction === CurveFunction.Polynomial;
  }

  get selectedCurveFunction(): CurveFunction | undefined {
    return this.clonedCurveData?.curveFunction;
  }

  get selectedCurveFunctionFormula(): string {
    if (!this.selectedCurveFunction) return "";
    return curveTypeFormula[this.selectedCurveFunction];
  }

  get curveParameters(): SfocPowerCurveParameter[] {
    if (!this.clonedCurveData || !this.selectedCurveFunction) return [];
    return this.clonedCurveData.parameters;
  }

  @Watch("isOpen")
  handleCurveDataChange(): void {
    this.clonedCurveData = JSON.parse(JSON.stringify(this.curveData));
  }

  @Watch("selectedCurveFunction")
  handleCurveFunctionChange(): void {
    if (!this.clonedCurveData) return;
    const parametersNeeded = ["A", "B", "C"];
    switch (this.selectedCurveFunction) {
      case CurveFunction.Hoerl:
        this.addMissingParameters(parametersNeeded);
        this.clonedCurveData.parameters = this.clonedCurveData.parameters.slice(0, 3);
        break;
      case CurveFunction.Polynomial:
        this.addMissingParameters(parametersNeeded);
        this.clonedCurveData.parameters = this.clonedCurveData?.parameters.slice(0, 4);
        break;
      case CurveFunction.Rational:
        parametersNeeded.push("D");
        this.addMissingParameters(parametersNeeded);
        this.clonedCurveData.parameters = this.clonedCurveData?.parameters.slice(0, 4);
        break;
      default:
        break;
    }
  }

  addMissingParameters(parametersNeeded: string[]): void {
    const missingParameters = parametersNeeded.filter(p => !this.clonedCurveData?.parameters.some(c => c.constant === p));
    missingParameters.forEach(parameter => {
      this.clonedCurveData?.parameters.push({ id: 0, curveDataId: 0, constant: parameter, value: 0 });
    });
  }

  numericFilter(event: any): number {
    return numberHelper.numericWithNegativeFilter(event);
  }

  replaceDecimalPeriod($event: any, ref: string): void {
    if (!$event.includes(",") || !$event) return;
    if (ref.includes("constant")) (this.$refs[ref] as any)[0].internalValue = $event.replace(/,/g, ".");
    else (this.$refs[ref] as any).internalValue = $event.replace(/,/g, ".");
  }

  isAddConstantEnabled(): boolean {
    return this.curveParameters.length < this.parameters.length;
  }

  isRemoveConstantEnabled(): boolean {
    return this.curveParameters.length > 3;
  }
  isAvailableToRemove(parameter: SfocPowerCurveParameter): boolean {
    return (parameter.constant === "D" || parameter.constant === "E" || parameter.constant === "F" || parameter.constant === "G") && this.showActions;
  }

  addParameter(): void {
    if (!this.clonedCurveData) return;
    if (this.clonedCurveData.parameters.length >= this.parameters.length) return;
    const parameter = this.parameters[this.clonedCurveData.parameters.length];
    this.clonedCurveData?.parameters.push({ id: 0, curveDataId: 0, constant: parameter, value: 0 });
  }

  removeParameter(parameter: SfocPowerCurveParameter): void {
    if (!this.clonedCurveData) return;
    if (this.clonedCurveData.parameters.length <= 3) return;
    const parameterIndex = this.clonedCurveData.parameters.findIndex(p => p.constant === parameter.constant);
    if (parameterIndex !== -1) {
      this.clonedCurveData.parameters.splice(parameterIndex, 1);
    }
  }

  saveChanges(): void {
    this.isOpen = false;
    this.$emit("onSave", this.clonedCurveData);
  }

  discardChanges(): void {
    this.isOpen = false;
  }
}
