var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"feature-map-table"},[(_vm.sortedRows)?_c('v-data-table',{staticClass:"data-quality-table",attrs:{"loading":_vm.loading,"items":_vm.syncRows,"headers":_vm.headers,"item-key":"key","items-per-page":_vm.pageLimit,"hide-default-footer":_vm.isLessThanLimit,"hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((_vm.headers),function(header){return _c('th',{key:header.text,class:_vm.headerClasses(header),style:(_vm.headerStyles(header)),on:{"click":function($event){return _vm.sort(header.value)}}},[_c('span',[_vm._v(_vm._s(header.text))]),(header.tooltip !== undefined)?_c('v-tooltip',{attrs:{"bottom":"","color":"black","content-class":"with-triangle","max-width":"280"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"darkGrey75","size":"medium"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',{staticClass:"tooltip-text"},[_vm._v(_vm._s(header.tooltip))])]):_vm._e(),(header.sortable)?_c('span',[(_vm.sortBy === header.value)?_c('v-icon',{class:{'rotate-icon': _vm.sortDesc},attrs:{"small":""}},[_vm._v(" mdi-arrow-up ")]):_c('v-icon',{staticClass:"sort-icon v-data-table-header__icon",attrs:{"small":""}},[_vm._v(" mdi-arrow-up ")])],1):_vm._e(),_c('v-menu',{attrs:{"content-class":"menu-table-actions","transition":"slide-x-transition","offset-x":true,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(header.type === 'variable')?_c('v-btn',_vm._g(_vm._b({staticClass:"btn-table-actions",attrs:{"icon":"","plain":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-dots-vertical")])],1):_vm._e()]}}],null,true)},[_c('v-list',{staticClass:"menu-table-actions__list"},[_c('v-list-item',{staticClass:"cursor-pointer"},[_c('v-list-item-title',{on:{"click":_vm.expandRows}},[_vm._v("Expand all")])],1),_c('v-list-item',{staticClass:"cursor-pointer"},[_c('v-list-item-title',{on:{"click":_vm.collapseRows}},[_vm._v("Collapse all")])],1)],1)],1)],1)}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(!_vm.loading)?_c('tr',{class:{ 'expanded-row': _vm.isRowExpanded(item.key) }},_vm._l((_vm.headers),function(header,index){return _c('td',{key:index},[_c('DataCell',{attrs:{"data":_vm.cellData(item, header, index),"isRowExpanded":_vm.isRowExpanded(item.key)},on:{"click":_vm.toggleRow}})],1)}),0):_vm._e(),(_vm.expandedRows.includes(item.key) && _vm.isDataTagORVariableMultiple(item))?_vm._l((item.datatag.list),function(tag,index){return _c('tr',{key:tag.name},[_c('td'),_c('td',{staticStyle:{"width":"280px","min-width":"280px"}},[_c('div',[_vm._v(" "+_vm._s(tag.name)+" ")])]),_c('td',{staticStyle:{"width":"280px","min-width":"280px"}},[_c('div',[_vm._v(" "+_vm._s(item.variable.list[index].name)+" ")])])])}):_vm._e()]}},{key:"progress",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody","types":_vm.skeletonStructure}})]},proxy:true}],null,false,59120305)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }