



















































































































































































































































































import { Component, Prop, PropSync, Vue, Watch, Ref } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import { curveTypeFormula } from "@/consts/curveConfigDefaults";
import numberHelper from "Utilities/number-helper";
import _ from "underscore";
//  components
import VesselSelectSimple from "@/components/VesselSelectSimple.vue";
import SfocPowerCurveEditDialog from "@/components/referencecurves/SfocPowerCurveEditDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
// types
import { CurveFunction } from "@/types/PowerSpeedCurveConfig";
import { Vessel } from "@/types/Vessel";
import { Company } from "@/types/company";
import { SfocPowerConfig, SfocPowerCurve, EngineTier } from "@/types/SFOCPowerCurveConfig";
//  clients
import VesselsClient from "Clients/vessels-client";
//  modules
import CompaniesModule from "@/store/clients/Companies.module";
import UserModule from "@/store/clients/User.module";
import SFOCPowerCurveClient from "@/Scripts/clients/sfoc-power-curve-client";

const Companies = getModule(CompaniesModule, store);
const User = getModule(UserModule, store);

@Component({
  components: {
    VesselSelectSimple,
    SfocPowerCurveEditDialog,
    ConfirmDialog,
  },
})
export default class SFOCPowerConfigEditDialog extends Vue {
  @Ref("confirmDelete") confirmDelete!: any;
  @PropSync("value", { required: true }) isOpen!: boolean;
  @PropSync("maxWidth", { default: 1000 }) dialogWidth!: number;
  @Prop({ required: true }) config!: SfocPowerConfig;
  @Prop({ required: true }) allVessels!: Vessel[];
  selectedTab = 0;
  clonedConfig: SfocPowerConfig | null = null;
  selectedVessels: Vessel[] = [];
  selectedCompanyId: number | null = null;
  showAllCompany: Partial<Company> = { name: "Show All Vessels", id: 0 };

  editCurveDialog = false;
  curveToEdit: SfocPowerCurve | null = null;

  headers = [
    {
      text: "Engines",
      value: "engines.length",
      class: "v-data-table-column-header",
      width: "33%",
      sortable: true,
    },
    {
      text: "Tier",
      value: "tier",
      class: "v-data-table-column-header",
      width: "33%",
      sortable: true,
    },
    {
      text: "Tier value",
      value: "tierValue",
      class: "v-data-table-column-header",
      width: "33%",
    },
    {
      text: "Segments",
      value: "segments.length",
      class: "v-data-table-column-header",
      width: "14%",
    },
    {
      text: "Edit",
      value: "edit",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
    {
      text: "Delete",
      value: "delete",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
  ];
  searchQuery: string | null = null;
  debounceLoader = false;
  currentCompanyVessels: Vessel[] = [];

  @Watch("config")
  handleConfigChange(): void {
    this.clonedConfig = JSON.parse(JSON.stringify(this.config));
    const alreadySelectedVesselIds = this.clonedConfig?.vesselIds;
    this.selectedVessels = this.allVessels.filter(v => alreadySelectedVesselIds?.some(i => i === v.id));
  }

  get companies(): Company[] {
    return Companies.companies;
  }

  get userCompanyId(): number | null {
    return User.userCompanyId;
  }

  get filteredVessels(): Vessel[] {
    if (this.searchQuery == null) return this.selectedCompanyId === 0 ? this.allVessels : this.currentCompanyVessels;
    const vessels = this.selectedCompanyId === 0 ? this.allVessels : this.currentCompanyVessels;
    return vessels.filter(v => v.name.toLowerCase().includes((this.searchQuery as string).toLowerCase()));
  }

  get isNoData(): boolean {
    return Boolean(this.clonedConfig?.curves.length === 0);
  }

  numericFilter(event: any): number {
    return numberHelper.numericWithNegativeFilter(event);
  }

  replaceDecimalPeriod($event: any, ref: string): void {
    if (!$event.includes(",") || !$event) return;
    (this.$refs[ref] as any).internalValue = $event.replace(/,/g, ".");
  }

  async deleteCurve(curve: SfocPowerCurve): Promise<void> {
    const confirmed = await this.confirmDelete.open("Delete Curve", "Are you sure you want to delete this curve?");
    if (confirmed) {
      if (!this.clonedConfig) return;
      const index = this.clonedConfig.curves.indexOf(curve);
      if (index > -1) this.clonedConfig.curves.splice(index, 1);
    }
  }

  updateSearchQueryInput(newValue: string): void {
    this.debounceLoader = true;
    this.searchQuery = newValue;
    setTimeout(() => (this.debounceLoader = false), 500);
  }

  doSearchDebounced = _.debounce(this.updateSearchQueryInput, 1000);

  addNewCurve(): void {
    const newCurve: SfocPowerCurve = {
      id: 0,
      configId: this.config.id,
      tier: EngineTier.Tier2,
      tierValue: 0,
      segments: [],
      engines: [],
    };
    this.clonedConfig?.curves.push(newCurve);
    this.openCurveEditModal(newCurve);
  }

  curveFormula(curveFunction: CurveFunction): string {
    return curveTypeFormula[curveFunction];
  }

  async saveConfig(): Promise<void> {
    if (this.clonedConfig) {
      this.clonedConfig.vesselIds = this.selectedVessels.map(v => v.id);
      await SFOCPowerCurveClient.updateConfig(this.clonedConfig);
    }
    this.isOpen = false;
    this.$emit("onConfigSave");
  }

  discardChanges(): void {
    this.handleConfigChange();
    this.isOpen = false;
  }

  openCurveEditModal(curve: SfocPowerCurve): void {
    this.editCurveDialog = true;
    this.curveToEdit = curve;
  }

  async onCompanyChanged(): Promise<void> {
    this.currentCompanyVessels = await VesselsClient.getVesselsByCompanyId(this.selectedCompanyId);
  }

  handleCurveSave(updatedCurve: SfocPowerCurve): void {
    if (!this.clonedConfig) return;
    const index = this.clonedConfig.curves.findIndex(c => c.id === updatedCurve.id);
    if (index > -1) {
      this.clonedConfig.curves.splice(index, 1, updatedCurve);
    }
  }

  async created(): Promise<void> {
    await Companies.refreshCompanies();
    this.currentCompanyVessels = await VesselsClient.getVessels();
    this.companies.unshift(this.showAllCompany as Company);
    this.selectedCompanyId = this.userCompanyId;
  }
}
