import net from "@/Scripts/app/net";
/* eslint-disable */
class FeatureMapClient {
  constructor() {}

  getFeatureMapList(vesselid) {
    return net.get(`/featuremapping/configuration?vesselid=${vesselid}`);
  }
}

export default new FeatureMapClient();
