import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";
export const headers: DataQualityHeader[] = [
  {
    text: "Vessel",
    type: "string",
    align: "left",
    value: "vesselName",
    width: "260px",
  },
  {
    text: "Overall Status",
    type: "dataIndicator",
    align: "left",
    value: "overallStatus",
    tooltip: "Indicates overall data outliers status of the vessel and is determined by data tag with the most critical status",
  },
];
