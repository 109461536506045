











































































































import { Component, Prop, Vue } from "vue-property-decorator";
//  components

//  types
import { CellData } from "@/types/dataQuality/dataQualityTable";

@Component({
  components: {},
})
export default class DataCell extends Vue {
  @Prop({ type: Object }) data!: CellData;
  @Prop({ type: Boolean, default: false }) isRowExpanded!: boolean;

  //  @Getters
  get isMultipleType(): boolean {
    if (!this.data) return false;
    return Boolean(this.data.list && this.data.list.length > 1);
  }

  get isSingleType(): boolean {
    if (!this.data) return false;
    return Boolean(this.data.list && this.data.list.length === 1);
  }

  get isAggregated(): boolean {
    if (!this.data || !this.data.relatedFeatures) return false;
    return this.data.relatedFeatures.length > 0;
  }

  get listAmount(): number {
    if (!this.data || !this.data.list) return 0;
    //  don't include first item that should be shown
    return this.data.list.length - 1;
  }

  get listFirstValue(): string {
    if (!this.data || !this.data.list) return "";
    return this.data.list[0].name;
  }

  get listWithoutFirstValue(): any[] {
    if (!this.data || !this.data.list) return [];
    return this.data.list.slice(1);
  }

  get relatedFeatures(): string {
    if (!this.data.relatedFeatures) return "";
    return this.data.relatedFeatures.map(f => f.name).join(", ");
  }
  //  @Methods
}
