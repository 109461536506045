import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import featureMapClient from "Clients/feature-map-client.js";
import { FeatureMapItem } from "@/types/FeatureMap";

@Module({ namespaced: true, name: "FeatureMap" })
class FeatureMap extends VuexModule {
  private _featureMapListLoadingState = false;
  private _featureMapList: FeatureMapItem[] = [];

  // @Getters
  public get featureMapList(): FeatureMapItem[] {
    return this._featureMapList;
  }

  public get featureMapListLoadingState(): boolean {
    return this._featureMapListLoadingState;
  }

  // @Actions
  @Action({ rawError: true })
  public async fetchFeatureMapList(vesselId: number): Promise<void> {
    try {
      this.context.commit("SET_LOADING_STATE", true);
      let featureMapList = await featureMapClient.getFeatureMapList(vesselId);
      this.context.commit("FETCH_FEATURE_MAP_LIST", featureMapList);
      this.context.commit("SET_LOADING_STATE", false);
    } catch (error) {
      throw ({ message: "Failed to fetch features mapping -> ", error });
    }
  }

  // @Mutations

  @Mutation
  public FETCH_FEATURE_MAP_LIST(featureMapList: FeatureMapItem[]): void {
    this._featureMapList = featureMapList;
  }
  @Mutation
  public SET_LOADING_STATE(loading: boolean): void {
    this._featureMapListLoadingState = loading;
  }

}

export default FeatureMap;
