























































































































































import { Component, Prop, PropSync, Ref, Vue, Watch } from "vue-property-decorator";
import numericFilter from "Utilities/numericFilter";
//  types
import { CurveFunction } from "@/types/PowerSpeedCurveConfig";
import { SfocPowerConfig, SfocPowerCurve, EngineTier, SfocPowerCurveData, SfocPowerCurveEngine, EngineType } from "@/types/SFOCPowerCurveConfig";
//  components
import SfocPowerCurveDataEditDialog from "@/components/referencecurves/SfocPowerCurveDataEditDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

@Component({
  components: {
    SfocPowerCurveDataEditDialog,
    ConfirmDialog,
  },
})
export default class SfocPowerCurveEditDialog extends Vue {
  @PropSync("value", { required: true }) isOpen!: boolean;
  @PropSync("maxWidth", { default: 1000 }) dialogWidth!: number;
  @Prop({ required: true }) config!: SfocPowerConfig;
  @Prop({ required: true }) curve!: SfocPowerCurve;
  @Ref("confirmDelete") confirmDelete!: any;

  clonedCurve: SfocPowerCurve | null = null;
  editCurveSegmentDialog = false;
  curveSegmentToEdit: SfocPowerCurveData | null = null;
  selectedEngines: EngineType[] = [];
  headers = [
    {
      text: "Curve",
      value: "curveFunction",
      class: "v-data-table-column-header",
      width: "45%",
      sortable: true,
    },
    {
      text: "Power Range",
      value: "power",
      class: "v-data-table-column-header",
      width: "45%",
    },
    {
      text: "Edit",
      value: "edit",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
    {
      text: "Delete",
      value: "delete",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
  ];

  get TierOptions(): string[] {
    return Object.keys(EngineTier);
  }

  get EngineOptions(): string[] {
    return Object.keys(EngineType);
  }

  get isNoData(): boolean {
    return Boolean(this.clonedCurve?.segments.length === 0);
  }

  @Watch("isOpen")
  handleCurveDataChange(): void {
    this.clonedCurve = JSON.parse(JSON.stringify(this.curve));
    this.selectedEngines = this.clonedCurve?.engines.map(e => e.engineType) ?? [];
  }

  numericFilter(event: any): number {
    return numericFilter(event);
  }

  openTierDataCurveEditModal(segment: SfocPowerCurveData): void {
    this.editCurveSegmentDialog = true;
    this.curveSegmentToEdit = segment;
  }

  async deleteSegment(segment: SfocPowerCurveData): Promise<void> {
    const confirmed = await this.confirmDelete.open("Delete Curve Segment", "Are you sure you want to delete this curve segment?");
    if (confirmed) {
      if (!this.clonedCurve) return;
      const index = this.clonedCurve.segments.indexOf(segment);
      if (index > -1) this.clonedCurve.segments.splice(index, 1);
    }
  }

  addNewSegment(): void {
    const newSegment: SfocPowerCurveData = {
      id: 0,
      curveId: this.clonedCurve?.id ?? 0,
      curveFunction: CurveFunction.Polynomial,
      fromPower: 0,
      toPower: 0,
      parameters: [],
    };
    this.clonedCurve?.segments.push(newSegment);
    this.openTierDataCurveEditModal(newSegment);
  }

  handleCurveSegmentSave(segment: SfocPowerCurveData): void {
    if (!this.clonedCurve) return;
    const index = this.clonedCurve.segments.findIndex(c => c.id === segment.id);
    if (index > -1) {
      this.clonedCurve.segments.splice(index, 1, segment);
    }
  }

  saveChanges(): void {
    if (this.clonedCurve) {
      this.clonedCurve.engines = this.selectedEngines.map(e => ({ id: 0, engineType: e, curveId: this.clonedCurve?.id })) as SfocPowerCurveEngine[];
    }
    this.isOpen = false;
    this.$emit("onSave", this.clonedCurve);
  }

  discardChanges(): void {
    this.isOpen = false;
  }
}
