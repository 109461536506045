var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"data-quality-table",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.selection,"item-key":"id","show-select":""},scopedSlots:_vm._u([{key:"header.vesselName",fn:function(ref){
var header = ref.header;
return [(!_vm.loading)?_c('div',{staticClass:"th-cell d-inline-flex"},[_c('span',[_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{attrs:{"color":"secondary50","size":"18"}},[_vm._v("mdi-filter-variant")])],1),_c('v-select',{staticClass:"custom-select",attrs:{"items":_vm.vessels,"menu-props":{
          maxHeight: '400',
          contentClass: 'custom-select-content',
          transition: 'slide-x-transition',
          openOnHover: true,
          },"item-text":"name","return-object":"","multiple":""},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-list-item',{staticClass:"cursor-pointer",on:{"click":_vm.clearSelection}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Clear selection ")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.filterByVessels),callback:function ($$v) {_vm.filterByVessels=$$v},expression:"filterByVessels"}})],1):_vm._e()]}},{key:"header.featureName",fn:function(ref){
          var header = ref.header;
return [(!_vm.loading)?_c('div',{staticClass:"th-cell d-inline-flex"},[_c('span',[_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{attrs:{"color":"secondary50","size":"18"}},[_vm._v("mdi-filter-variant")])],1),_c('v-select',{staticClass:"custom-select",attrs:{"items":_vm.dataTagList,"menu-props":{
          maxHeight: '400',
          contentClass: 'custom-select-content',
          transition: 'slide-x-transition',
          openOnHover: true,
          },"item-text":"name","return-object":"","multiple":""},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-list-item',{staticClass:"cursor-pointer",on:{"click":_vm.clearSelection}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Clear selection ")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.filterByTags),callback:function ($$v) {_vm.filterByTags=$$v},expression:"filterByTags"}})],1):_vm._e()]}},{key:"item.value",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.value)+" "+_vm._s(_vm.featureUnit(item.featureName)))])]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})}
var staticRenderFns = []

export { render, staticRenderFns }