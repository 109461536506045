

















































































































































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import DataCell from "./DataCell.vue";
import { CellData, DataQualityHeader, DataQualityRow } from "@/types/dataQuality/dataQualityTable";

@Component({
  components: {
    DataCell,
  },
})
export default class FeatureMapTable extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: Boolean;
  @Prop({ type: Array, default: () => [] }) headers!: DataQualityHeader[];
  @Prop({ type: Array, default: () => [] }) rows!: DataQualityRow[];
  @Prop({ type: Array, default: () => [] }) links!: string[] | null;

  sortBy: string | null = "feature.value";
  sortDesc: boolean | null = false;
  pageLimit = 10;
  expandedRows: number[] = [];
  syncRows: DataQualityRow[] = [];

  @Watch("sortedRows", { deep: true })
  onSortedRowsChanged() {
    this.syncRows = this.sortedRows;
  }

  //  @Getters
  get isLessThanLimit(): boolean {
    return this.rows.length <= this.pageLimit;
  }

  get sortedRows() {
    if (this.sortBy === null) {
      return this.rows;
    }

    return this.rows.slice().sort((a, b) => {
      if (this.sortBy === "datatag.value" || this.sortBy === "variable.value") {
        const typeA = a[this.sortBy === "datatag.value" ? "datatag" : "variable"].type;
        const typeB = b[this.sortBy === "datatag.value" ? "datatag" : "variable"].type;

        if (this.sortDesc) {
          if (typeA === "multiple" && typeB !== "multiple") return -1;
          if (typeA !== "multiple" && typeB === "multiple") return 1;
          if (typeA === "single" && typeB !== "single") return 1;
          if (typeA !== "single" && typeB === "single") return -1;
        } else {
          if (typeA === "single" && typeB !== "single") return -1;
          if (typeA !== "single" && typeB === "single") return 1;
          if (typeA === "multiple" && typeB !== "multiple") return 1;
          if (typeA !== "multiple" && typeB === "multiple") return -1;
        }
      }

      const sortA = a[this.sortBy as any]?.value || "";
      const sortB = b[this.sortBy as any]?.value || "";
      if (this.sortDesc) {
        return sortA < sortB ? 1 : -1;
      } else {
        return sortA > sortB ? 1 : -1;
      }
    });
  }

  get skeletonStructure(): object {
    return { "table-row": `table-cell@3` };
  }

  //  @Methods
  cellData(row: DataQualityRow, header: DataQualityHeader, index: number): any {
    return {
      column: header.value,
      columnName: header.text,
      index: index,
      rowIndex: row.key,
      type: header.type === "string" ? "string" : row[header.value].type,
      align: header.align,
      value: row[header.value].value,
      list: row[header.value].list,
      relatedFeatures: row[header.value].relatedFeatures,
      unit: row[header.value].unit,
      featureDescription: header.value === "feature" ? row[header.value].description : null,
    };
  }

  toggleRow(rowIndex: number) {
    const index = this.expandedRows.indexOf(rowIndex);
    if (index === -1) {
      this.expandedRows.push(rowIndex);
    } else {
      this.expandedRows.splice(index, 1);
    }
  }

  isRowExpanded(key: number): boolean {
    return this.expandedRows.includes(key);
  }

  expandRows(): void {
    this.expandedRows = this.rows.map(row => Number(row.key));
  }

  collapseRows(): void {
    this.expandedRows = [];
  }

  sort(value: string) {
    if (this.sortBy === value) {
      if (this.sortDesc === null) {
        this.sortDesc = false;
      } else if (this.sortDesc === false) {
        this.sortDesc = true;
      } else {
        this.sortBy = null;
        this.sortDesc = null;
      }
    } else {
      this.sortBy = value;
      this.sortDesc = false;
    }
  }

  isDataTagORVariableMultiple(item: DataQualityRow): boolean {
    return item.datatag.type === "multiple" || item.variable.type === "multiple";
  }

  headerClasses(header: any): string[] {
    return [
      header.class,
      {
        sortable: header.sortable,
        "no-sort": this.sortBy !== header.value,
      },
    ];
  }

  headerStyles(header: any): any {
    return {
      width: header.width ? header.width : "auto",
      "min-width": header.width ? header.width : "auto",
    };
  }
}
